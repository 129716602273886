import { Refresh } from '@mui/icons-material';
import {
  Button,
  Chip,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CommonSearch, Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { betService } from 'services';
import { formatNumber, formatDate } from 'utils/common';
import { PopupUpdateSession } from 'views/Session/popups';
import { GATE_TYPES, PopupUpdateBet } from './popups';

const BetList = () => {
  const { isSale } = useSelector(profileSelector);

  const [dataSearch, onSearchChange] = useSearch();

  const { data, isFetching, refetch } = useQuery(
    ['betService.fetchBets', dataSearch],
    () => betService.fetchBets(dataSearch),
    { keepPreviousData: true },
  );
  const { results = [], page: currentPage, totalResults, totalPages } = data ?? {};

  const [openUpdatePopup, setOpenUpdatePopup] = useState(false);
  const [itemChoice, setItemChoice] = useState<BetType>();

  const [openUpdateResult, setOpenUpdateResult] = useState(false);
  const [sessionChoice, setSessionChoice] = useState<SessionType>();

  return (
    <>
      <div className='flex items-center justify-between'>
        <CommonSearch
          items={[
            { label: 'Số kỳ', key: 'sessionIncId' },
            { label: 'ID người chơi', key: 'playerIncId' },
          ]}
          onChange={onSearchChange}
        />
        <Button variant='outlined' color='primary' startIcon={<Refresh />} onClick={() => refetch()}>
          Làm mới
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Spinner loading={isFetching}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Số kỳ</TableCell>
                <TableCell>Loại</TableCell>
                <TableCell>ID người chơi</TableCell>
                <TableCell>Đặt cược</TableCell>
                <TableCell align='right'>Tiền cược</TableCell>
                <TableCell align='right'>Tiền thắng</TableCell>
                <TableCell>Trạng thái</TableCell>
                <TableCell>Kết quả</TableCell>
                <TableCell>Thời gian</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.session?.incId}</TableCell>
                  <TableCell>
                    <Chip label={item.session?.zone.replace('_', ' ')} color='info' />
                  </TableCell>
                  <TableCell>{item.player.incId}</TableCell>
                  <TableCell className='whitespace-nowrap'>
                    <span className='inline-block w-[60px] mr-3'>
                      {GATE_TYPES.find((type) => type.code === item.gate)?.label}
                    </span>
                    {isSale && (
                      <Button
                        variant='outlined'
                        color='primary'
                        size='small'
                        disabled={item.session?.status !== 'OPEN'}
                        onClick={() => {
                          setItemChoice(item);
                          setOpenUpdatePopup(true);
                        }}
                      >
                        Sửa đặt cược
                      </Button>
                    )}
                  </TableCell>
                  <TableCell align='right'>{formatNumber(item.amount)}</TableCell>
                  <TableCell align='right'>{formatNumber(item.winnings)}</TableCell>
                  <TableCell>
                    {item.session?.status === 'OPEN' && <Chip label='Đang mở' color='success' />}
                    {item.session?.status === 'FINISHED' && <Chip label='Kết thúc' color='default' />}
                  </TableCell>
                  <TableCell className='whitespace-nowrap'>
                    <span className='inline-block w-[60px] mr-3'>{item.session?.result}</span>
                    {isSale && (
                      <Button
                        variant='outlined'
                        color='primary'
                        size='small'
                        disabled={item.session?.status !== 'OPEN'}
                        onClick={() => {
                          setSessionChoice(item.session);
                          setOpenUpdateResult(true);
                        }}
                      >
                        Sửa kết quả
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>{formatDate(item.createdAt)}</TableCell>
                </TableRow>
              ))}
              <TableRowEmpty visible={!isFetching && results.length === 0} />
            </TableBody>
            <caption>{totalResults ?? 0} Lệnh</caption>
          </Table>
        </Spinner>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={currentPage ?? 1}
          count={totalPages}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>

      <Dialog open={openUpdatePopup}>
        <PopupUpdateBet item={itemChoice!} onClose={() => setOpenUpdatePopup(false)} />
      </Dialog>
      <Dialog open={openUpdateResult}>
        <PopupUpdateSession item={sessionChoice!} onClose={() => setOpenUpdateResult(false)} onSuccess={refetch} />
      </Dialog>
    </>
  );
};

export default BetList;
