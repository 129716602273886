import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { betService, queryClient } from 'services';
import { GATE_TYPES } from '.';

type Props = PopupController & {
  item: BetType;
};

const PopupUpdateBet = ({ item, onClose }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: updateBet, isLoading } = useMutation(betService.updateBet, {
    onSuccess: () => {
      enqueueSnackbar('Đã thay đổi đặt cược');
      queryClient.invalidateQueries(['betService.fetchBets']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      updateBet({
        ...(values as BetUpdateType),
        id: item.id,
      });
    })();
  };

  return (
    <>
      <DialogTitle>Chỉnh sửa đặt cược</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Controller
              name='gate'
              defaultValue={item.gate}
              control={control}
              render={({ field }) => (
                <TextField {...field} fullWidth select label='Đặt cược'>
                  {GATE_TYPES.map((item, index) => (
                    <MenuItem key={index} value={item.code}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy
        </LoadingButton>
        <LoadingButton variant='contained' color='secondary' loading={isLoading} onClick={handleClickSubmit}>
          Cập nhật
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupUpdateBet;
