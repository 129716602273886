import { Refresh } from '@mui/icons-material';
import { default as Countdown } from 'react-countdown';
import {
  Button,
  Chip,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CommonSearch, Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { profileSelector } from 'reducers/profileSlice';
import { sessionService } from 'services';
import { formatNumber, formatDate } from 'utils/common';
import { PopupUpdateSession } from './popups';

const SessionList = () => {
  const params = useParams();
  const { isSale } = useSelector(profileSelector);

  const [dataSearch, onSearchChange] = useSearch();

  const { data, isFetching, refetch } = useQuery(
    ['sessionService.fetchSessions', dataSearch, params],
    () => sessionService.fetchSessions({ ...dataSearch, zone: `LEVEL_${params.zone}` }),
    { keepPreviousData: true },
  );
  const { results = [], page: currentPage, totalResults, totalPages } = data ?? {};

  const [openUpdatePopup, setOpenUpdatePopup] = useState(false);
  const [itemChoice, setItemChoice] = useState<SessionType>();

  return (
    <>
      <div className='flex items-center justify-sessionween'>
        <CommonSearch items={[{ label: 'Số kỳ', key: 'incId' }]} onChange={onSearchChange} />
        <Button variant='outlined' color='primary' startIcon={<Refresh />} onClick={() => refetch()}>
          Làm mới
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Spinner loading={isFetching}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Số kỳ</TableCell>
                <TableCell>Loại</TableCell>
                <TableCell>Kết quả</TableCell>
                <TableCell align='right'>Tổng tiền cược</TableCell>
                <TableCell>Trạng thái</TableCell>
                <TableCell>Thời gian bắt đầu</TableCell>
                <TableCell colSpan={2}>Thời gian kết thúc</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.incId}</TableCell>
                  <TableCell>
                    <Chip label={item.zone.replace('_', ' ')} color='info' />
                  </TableCell>
                  <TableCell>{item.result}</TableCell>
                  <TableCell align='right'>{formatNumber(item.totalBet)}</TableCell>
                  <TableCell>
                    {item.status === 'OPEN' && <Chip label='Đang mở' color='success' />}
                    {item.status === 'PREPARING' && <Chip label='Chuẩn bị' color='info' variant='outlined' />}
                    {item.status === 'FINISHED' && <Chip label='Kết thúc' color='default' />}
                  </TableCell>
                  <TableCell>{formatDate(item.startTime)}</TableCell>
                  <TableCell>{formatDate(item.endTime)}</TableCell>
                  <TableCell className='w-20'>
                    {item.status === 'OPEN' && (
                      <Countdown
                        date={item.endTime}
                        onComplete={() => refetch()}
                        renderer={({ formatted: { minutes, seconds } }) => `${minutes}:${seconds}`}
                      />
                    )}
                  </TableCell>
                  <TableCell align='right' className='space-x-2'>
                    {isSale && (
                      <Button
                        variant='outlined'
                        color='secondary'
                        size='small'
                        disabled={item.status === 'FINISHED'}
                        onClick={() => {
                          setItemChoice(item);
                          setOpenUpdatePopup(true);
                        }}
                      >
                        Sửa kết quả
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              <TableRowEmpty visible={!isFetching && results.length === 0} />
            </TableBody>
            <caption>{totalResults ?? 0} Kết quả</caption>
          </Table>
        </Spinner>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={currentPage ?? 1}
          count={totalPages}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>

      <Dialog open={openUpdatePopup}>
        <PopupUpdateSession item={itemChoice!} onClose={() => setOpenUpdatePopup(false)} />
      </Dialog>
    </>
  );
};

export default SessionList;
