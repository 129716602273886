import { Refresh } from '@mui/icons-material';
import {
  Button,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CommonSearch, Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { rewardService } from 'services';
import { formatDate, formatNumber } from 'utils/common';
import { PopupDeleteReward, PopupExchangeReward } from './popups';

const RewardList = () => {
  const [dataSearch, onSearchChange] = useSearch();

  const { data, isFetching, refetch } = useQuery(
    ['rewardService.fetchRewards', dataSearch],
    () => rewardService.fetchRewards(dataSearch),
    { keepPreviousData: true },
  );
  const { results = [], page: currentPage, totalResults, totalPages } = data ?? {};

  const [openExchangePopup, setOpenExchangePopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [itemChoice, setItemChoice] = useState<RewardType>();

  return (
    <>
      <div className='flex items-center justify-between'>
        <CommonSearch items={[{ label: 'ID người chơi', key: 'playerIncId' }]} onChange={onSearchChange} />
        <Button variant='outlined' color='primary' startIcon={<Refresh />} onClick={() => refetch()}>
          Làm mới
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Spinner loading={isFetching}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID người chơi</TableCell>
                <TableCell>Quà tặng</TableCell>
                <TableCell align='right'>Giá trị</TableCell>
                <TableCell>Thời gian</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.player.incId}</TableCell>
                  <TableCell>{item.reward.name}</TableCell>
                  <TableCell align='right'>{formatNumber(item.reward.price)}</TableCell>
                  <TableCell>{formatDate(item.createdAt)}</TableCell>
                  <TableCell align='right' className='whitespace-nowrap space-x-2'>
                    <Button
                      variant='outlined'
                      color='success'
                      size='small'
                      disabled={!item.isActive}
                      onClick={() => {
                        setItemChoice(item);
                        setOpenExchangePopup(true);
                      }}
                    >
                      Duyệt
                    </Button>
                    <Button
                      variant='outlined'
                      color='error'
                      size='small'
                      disabled={!item.isActive}
                      onClick={() => {
                        setItemChoice(item);
                        setOpenDeletePopup(true);
                      }}
                    >
                      Xoá
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableRowEmpty visible={!isFetching && results.length === 0} />
            </TableBody>
            <caption>{totalResults ?? 0} Nhận thưởng</caption>
          </Table>
        </Spinner>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={currentPage ?? 1}
          count={totalPages}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>

      <Dialog open={openExchangePopup}>
        <PopupExchangeReward item={itemChoice!} onClose={() => setOpenExchangePopup(false)} />
      </Dialog>
      <Dialog open={openDeletePopup}>
        <PopupDeleteReward item={itemChoice!} onClose={() => setOpenDeletePopup(false)} />
      </Dialog>
    </>
  );
};

export default RewardList;
