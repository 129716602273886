import { Logout, Menu } from '@mui/icons-material';
import { AppBar, Avatar, Button, Chip, Drawer, IconButton, Toolbar, Tooltip } from '@mui/material';
import { AppBreadcrumb, AppMenu } from 'containers';
import { useWindowSize } from 'hooks';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { profileSelector, signOut } from 'reducers/profileSlice';
import { privateRoute } from 'routes';
import { USER_TYPES } from 'views/User/popups';

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { isMobile } = useWindowSize();
  const { isLoggedIn, username, role } = useSelector(profileSelector);

  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    setOpenDrawer(false);
  }, [location.pathname]);

  return (
    <>
      <Drawer
        variant={isMobile ? 'temporary' : 'persistent'}
        anchor='left'
        open={isMobile ? openDrawer : true}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{ style: { width: '320px', padding: '8px 16px' } }}
      >
        <div className='flex justify-center items-center'>
          <Link to={privateRoute.home.path}>
            <img src={require('assets/icons/Logo.App.svg').default} className='h-[80px] mt-[-12px]' />
          </Link>
        </div>
        <AppMenu />
      </Drawer>

      <AppBar position='sticky' className='bg-white' elevation={1}>
        <Toolbar>
          {isMobile && (
            <IconButton onClick={() => setOpenDrawer(true)} className='mr-2'>
              <Menu />
            </IconButton>
          )}
          <AppBreadcrumb />
          <div className='flex-1' />

          {isLoggedIn ? (
            <div className='flex-1 flex items-center justify-end'>
              <Chip color='info' label={USER_TYPES.find((type) => type.code === role)?.label} />
              <Tooltip title='Đăng xuất'>
                <IconButton className='mx-3' onClick={() => dispatch(signOut({}))}>
                  <Logout />
                </IconButton>
              </Tooltip>
              <Button variant='outlined' color='warning'>
                <Avatar className='text-sm h-6 w-6 mr-2'>K</Avatar>
                {username}
              </Button>
            </div>
          ) : (
            <Button variant='outlined'>Login</Button>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
