import { Add, Refresh } from '@mui/icons-material';
import {
  Button,
  Container,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { topService } from 'services';
import { formatDate, formatNumber } from 'utils/common';
import { PopupCreateTop, PopupDeleteTop, PopupUpdateTop } from './popups';

const TopList = () => {
  const [dataSearch, onSearchChange] = useSearch();

  const { data, isFetching, refetch } = useQuery(
    ['topService.fetchTops', dataSearch],
    () => topService.fetchTops(dataSearch),
    { keepPreviousData: true },
  );
  const { results = [], page: currentPage, totalResults, totalPages } = data ?? {};

  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [openUpdatePopup, setOpenUpdatePopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [itemChoice, setItemChoice] = useState<TopType>();

  return (
    <Container maxWidth='lg' className='ml-0 px-0'>
      <div className='flex items-center justify-between gap-3'>
        <div className='flex-1'></div>
        <Button variant='contained' color='success' startIcon={<Add />} onClick={() => setOpenCreatePopup(true)}>
          Thêm bản ghi
        </Button>
        <Button variant='outlined' color='primary' startIcon={<Refresh />} onClick={() => refetch()}>
          Làm mới
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Spinner loading={isFetching}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Giá trị</TableCell>
                <TableCell>Thời gian</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.idTop}</TableCell>
                  <TableCell align='right'>{formatNumber(item.price)}</TableCell>
                  <TableCell>{formatDate(item.updatedAt)}</TableCell>
                  <TableCell align='right' className='whitespace-nowrap space-x-2'>
                    <Button
                      variant='outlined'
                      color='secondary'
                      size='small'
                      onClick={() => {
                        setItemChoice(item);
                        setOpenUpdatePopup(true);
                      }}
                    >
                      Cập nhật
                    </Button>
                    <Button
                      variant='outlined'
                      color='error'
                      size='small'
                      onClick={() => {
                        setItemChoice(item);
                        setOpenDeletePopup(true);
                      }}
                    >
                      Xoá
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableRowEmpty visible={!isFetching && results.length === 0} />
            </TableBody>
            <caption>{totalResults ?? 0} Bản ghi</caption>
          </Table>
        </Spinner>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={currentPage ?? 1}
          count={totalPages}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>

      <Dialog open={openCreatePopup}>
        <PopupCreateTop onClose={() => setOpenCreatePopup(false)} />
      </Dialog>
      <Dialog open={openUpdatePopup}>
        <PopupUpdateTop item={itemChoice!} onClose={() => setOpenUpdatePopup(false)} />
      </Dialog>
      <Dialog open={openDeletePopup}>
        <PopupDeleteTop item={itemChoice!} onClose={() => setOpenDeletePopup(false)} />
      </Dialog>
    </Container>
  );
};

export default TopList;
