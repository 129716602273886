import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { InputNumber } from 'components';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { queryClient, topService } from 'services';

type PopupProps = PopupController & {
  item: TopType;
};

const PopupUpdateTop = ({ item, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: updateTop, isLoading } = useMutation(topService.updateTop, {
    onSuccess: () => {
      enqueueSnackbar('Đã cập nhật bảng xếp hạng');
      queryClient.invalidateQueries(['topService.fetchTops']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      updateTop({
        ...(values as TopUpdateType),
        id: item.id,
      });
    })();
  };

  return (
    <>
      <DialogTitle>Cập nhật bản ghi</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Controller
              name='idTop'
              defaultValue={item.idTop ?? ''}
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label='ID' />}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='price'
              defaultValue={item.price ?? ''}
              control={control}
              render={({ field }) => (
                <TextField {...field} fullWidth label='Giá trị' InputProps={{ inputComponent: InputNumber }} />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy
        </LoadingButton>
        <LoadingButton variant='contained' color='secondary' loading={isLoading} onClick={handleClickSubmit}>
          Cập nhật
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupUpdateTop;
