import { client } from './axios';

const fetchBanners = (params: PaginateParams): Promise<BannerPaginateType> => client.get(`/admin/banner`, { params });
const createBanner = (body: BannerCreateType): Promise<BannerType> => client.post(`/admin/banner`, body);
const updateBanner = ({ id, ...body }: BannerUpdateType): Promise<BannerType> =>
  client.patch(`/admin/banner/${id}`, body);
const deleteBanner = ({ id }: IDType): Promise<BannerType> => client.delete(`/admin/banner/${id}`);

export default {
  fetchBanners,
  createBanner,
  updateBanner,
  deleteBanner,
};
