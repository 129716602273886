import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { formatNumber } from 'utils/common';

const Line = ({ label, value }: { label?: string; value: any }) => {
  return (
    <div className='flex'>
      <div className='w-[180px]'>{label}</div>
      <div className='flex-1'>: {value}</div>
    </div>
  );
};

type PopupProps = PopupController & {
  item: PlayerType;
};

const PopupViewPlayer = ({ item: player, onClose }: PopupProps) => {
  return (
    <>
      <DialogTitle>Thông tin tài khoản</DialogTitle>

      <DialogContent className='space-y-4'>
        <Line label='ID' value={player?.incId} />
        <Line label='Username' value={player?.username} />
        <Divider />
        <Line label='Số dư' value={formatNumber(player?.balance)} />
        <Line label='Tổng thưởng' value={formatNumber(player?.totalBonus)} />
        <Line label='Tổng nạp' value={formatNumber(player?.totalDeposit)} />
        <Line label='Tổng rút' value={formatNumber(player?.totalWithdrawal)} />
        <Line label='Tổng cược' value={formatNumber(player?.totalBet)} />
        <Line label='Tổng thắng' value={formatNumber(player?.totalWinnings)} />
        <Line label='Tổng thua' value={formatNumber(player?.totalLoss)} />
        <Divider />
        <Line label='Ngân hàng' value={player?.bankName} />
        <Line label='Số tài khoản' value={player?.bankAccountNumber} />
        <Line label='Chủ tài khoản' value={player?.bankAccountHolder} />
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Đóng
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupViewPlayer;
