import { Add, Refresh } from '@mui/icons-material';
import {
  Button,
  Chip,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CommonSearch, Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { depositService } from 'services';
import { formatNumber, formatDate } from 'utils/common';
import { PopupCreateDeposit, PopupRevokeDeposit, PopupUpdateDeposit } from './popups';

const DepositList = () => {
  const { isAccount } = useSelector(profileSelector);

  const [dataSearch, onSearchChange] = useSearch();

  const { data, isFetching, refetch } = useQuery(
    ['depositService.fetchDeposits', dataSearch],
    () => depositService.fetchDeposits(dataSearch),
    { keepPreviousData: true },
  );
  const { results = [], page: currentPage, totalResults, totalPages } = data ?? {};

  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [openCreateSale, setOpenCreateSale] = useState(false);
  const [openUpdatePopup, setOpenUpdatePopup] = useState(false);
  const [openRevokePopup, setOpenRevokePopup] = useState(false);
  const [itemChoice, setItemChoice] = useState<DepositType>();

  return (
    <>
      <div className='flex items-center justify-between gap-3'>
        <CommonSearch items={[{ label: 'ID người chơi', key: 'playerIncId' }]} onChange={onSearchChange} />
        {isAccount && (
          <Button variant='contained' color='secondary' startIcon={<Add />} onClick={() => setOpenCreatePopup(true)}>
            Nạp tiền
          </Button>
        )}
        {isAccount && (
          <Button variant='contained' color='warning' startIcon={<Add />} onClick={() => setOpenCreateSale(true)}>
            Nạp khuyến mại
          </Button>
        )}
        <Button variant='outlined' color='primary' startIcon={<Refresh />} onClick={() => refetch()}>
          Làm mới
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Spinner loading={isFetching}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID người chơi</TableCell>
                <TableCell align='right'>Giá trị</TableCell>
                <TableCell>Trạng thái</TableCell>
                <TableCell>Hóa đơn</TableCell>
                <TableCell>Thời gian</TableCell>
                <TableCell>Ghi chú</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.player.incId}</TableCell>
                  <TableCell align='right'>{formatNumber(item.amount)}</TableCell>
                  <TableCell>
                    {item.status === 'SUCCESS' && <Chip label='Thành công' color='success' />}
                    {item.status === 'REVOKED' && <Chip label='Đã thu hồi' color='error' />}
                  </TableCell>
                  <TableCell>{item.hasBill ? <Chip label='Có' color='info' className='w-[80px]' /> : ''}</TableCell>
                  <TableCell>{formatDate(item.createdAt)}</TableCell>
                  <TableCell className='max-w-[320px]'>{item.note}</TableCell>
                  <TableCell align='right' className='whitespace-nowrap space-x-2'>
                    <Button
                      variant='outlined'
                      color='secondary'
                      size='small'
                      disabled={item.status === 'REVOKED'}
                      onClick={() => {
                        setItemChoice(item);
                        setOpenUpdatePopup(true);
                      }}
                    >
                      Cập nhật
                    </Button>
                    {isAccount && (
                      <Button
                        variant='outlined'
                        color='error'
                        size='small'
                        disabled={item.status === 'REVOKED'}
                        onClick={() => {
                          setItemChoice(item);
                          setOpenRevokePopup(true);
                        }}
                      >
                        Thu hồi
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              <TableRowEmpty visible={!isFetching && results.length === 0} />
            </TableBody>
            <caption>{totalResults ?? 0} Lệnh</caption>
          </Table>
        </Spinner>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={currentPage ?? 1}
          count={totalPages}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>

      <Dialog open={openCreatePopup}>
        <PopupCreateDeposit params={{ hasBill: true, isNotify: false }} onClose={() => setOpenCreatePopup(false)} />
      </Dialog>
      <Dialog open={openCreateSale}>
        <PopupCreateDeposit params={{ hasBill: false, isNotify: true }} onClose={() => setOpenCreateSale(false)} />
      </Dialog>
      <Dialog open={openUpdatePopup}>
        <PopupUpdateDeposit item={itemChoice!} onClose={() => setOpenUpdatePopup(false)} />
      </Dialog>
      <Dialog open={openRevokePopup}>
        <PopupRevokeDeposit item={itemChoice!} onClose={() => setOpenRevokePopup(false)} />
      </Dialog>
    </>
  );
};

export default DepositList;
