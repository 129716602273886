import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { sessionService, queryClient } from 'services';

type Props = PopupController & {
  item: SessionType;
};

const PopupUpdateSession = ({ item, onClose, onSuccess }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: updateSession, isLoading } = useMutation(sessionService.updateSession, {
    onSuccess: () => {
      enqueueSnackbar('Đã thay đổi kết quả');
      queryClient.invalidateQueries(['sessionService.fetchSessions']);
      onClose();
      onSuccess?.();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      updateSession({
        ...(values as SessionUpdateType),
        id: item.id,
      });
    })();
  };

  return (
    <>
      <DialogTitle>Chỉnh sửa kết quả</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Controller
              name='result'
              defaultValue={item.result}
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label='Kết quả' />}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy
        </LoadingButton>
        <LoadingButton variant='contained' color='secondary' loading={isLoading} onClick={handleClickSubmit}>
          Cập nhật
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupUpdateSession;
