import { AddAPhoto } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { CardMedia, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { InputNumber } from 'components';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { fileService, giftService, queryClient } from 'services';
import { GIFT_TYPES } from '../GiftList';

const PopupCreateGift = ({ onClose }: PopupController) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, setValue } = useForm({ mode: 'onChange' });

  const { mutate: createGift, isLoading } = useMutation(giftService.createGift, {
    onSuccess: () => {
      enqueueSnackbar('Đã tạo quà tặng');
      queryClient.invalidateQueries(['giftService.fetchGifts']);
      onClose();
    },
  });

  const handleChangeFile = (event: any) => {
    const [file] = event.target.files;
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    fileService.uploadFile(formData).then(({ fileUrl }) => {
      setValue('image', fileUrl, { shouldValidate: true });
    });
  };

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      createGift({
        ...(values as GiftCreateType),
        index: 1,
      });
    })();
  };

  return (
    <>
      <DialogTitle>Tạo mới quà tặng</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Controller
              name='image'
              defaultValue=''
              control={control}
              rules={{ required: true }}
              render={({ field: { value: image }, fieldState: { error } }) => (
                <>
                  <input hidden type='file' id='image' accept='image/*' onChange={handleChangeFile} />
                  <label
                    htmlFor='image'
                    className='flex rounded border-2 border-dashed cursor-pointer hover:border-neutral-400'
                    style={error ? { borderColor: 'orangered' } : {}}
                  >
                    <CardMedia
                      image={image}
                      className='flex justify-center items-center bg-contain bg-neutral-50 h-52 w-full'
                    >
                      {!image && <AddAPhoto className='w-16 h-16' sx={{ color: 'silver' }} />}
                    </CardMedia>
                  </label>
                </>
              )}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='name'
              defaultValue=''
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label='Tên phần quà' />}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='supplier'
              defaultValue=''
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label='Nhà cung cấp' />}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='description'
              defaultValue=''
              control={control}
              render={({ field }) => <TextField {...field} fullWidth multiline minRows={2} label='Nội dung' />}
            />
          </Grid>

          <Grid item sm={6}>
            <Controller
              name='price'
              defaultValue=''
              control={control}
              render={({ field }) => (
                <TextField {...field} fullWidth label='Giá trị' InputProps={{ inputComponent: InputNumber }} />
              )}
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='percentage'
              defaultValue=''
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label='Tỉ lệ'
                  InputProps={{
                    inputComponent: InputNumber,
                    inputProps: { decimalScale: 3 },
                    endAdornment: '%',
                  }}
                />
              )}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='type'
              defaultValue={GIFT_TYPES[0].code}
              control={control}
              render={({ field }) => (
                <TextField {...field} select fullWidth label='Loại quà'>
                  {GIFT_TYPES.map((item, index) => (
                    <MenuItem key={index} value={item.code}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy
        </LoadingButton>
        <LoadingButton variant='contained' color='success' loading={isLoading} onClick={handleClickSubmit}>
          Tạo quà tặng
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupCreateGift;
