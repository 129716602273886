import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { userService, queryClient } from 'services';
import { USER_TYPES } from '.';

const PopupCreateUser = ({ onClose }: PopupController) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: createUser, isLoading } = useMutation(userService.createUser, {
    onSuccess: () => {
      enqueueSnackbar('Đã tạo tài khoản');
      queryClient.invalidateQueries(['userService.fetchUsers']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      createUser({
        ...(values as UserCreateType),
      });
    })();
  };

  return (
    <>
      <DialogTitle>Tạo tài khoản</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Controller
              name='username'
              defaultValue=''
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label='Username' />}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='password'
              defaultValue=''
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label='Password' />}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='role'
              defaultValue='ADMIN'
              control={control}
              render={({ field }) => (
                <TextField {...field} fullWidth select label='Vai trò'>
                  {USER_TYPES.map((item, index) => (
                    <MenuItem key={index} value={item.code}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy
        </LoadingButton>
        <LoadingButton variant='contained' color='success' loading={isLoading} onClick={handleClickSubmit}>
          Tạo tài khoản
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupCreateUser;
