import { client } from './axios';

const fetchWithdraws = (params: PaginateParams): Promise<WithdrawPaginateType> =>
  client.get(`/admin/withdrawals`, { params });
const fetchStats = (params: PaginateParams): Promise<WithdrawPaginateType> =>
  client.get(`/admin/withdrawals/stats`, { params });
const updateWithdraw = ({ id, ...body }: WithdrawUpdateType): Promise<WithdrawType> =>
  client.patch(`/admin/withdrawals/${id}`, body);
const approveWithdraw = ({ id, ...body }: WithdrawUpdateType): Promise<WithdrawType> =>
  client.post(`/admin/withdrawals/${id}/approve`, body);
const rejectWithdraw = ({ id, ...body }: WithdrawUpdateType): Promise<WithdrawType> =>
  client.post(`/admin/withdrawals/${id}/reject`, body);

export default {
  fetchWithdraws,
  fetchStats,
  updateWithdraw,
  approveWithdraw,
  rejectWithdraw,
};
