import { Add, Refresh } from '@mui/icons-material';
import {
  Button,
  CardMedia,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { bannerService } from 'services';
import { PopupCreateBanner, PopupDeleteBanner, PopupUpdateBanner } from './popups';

const BannerList = () => {
  const [dataSearch, onSearchChange] = useSearch({ limit: 10 });

  const { data, isFetching, refetch } = useQuery(
    ['bannerService.fetchBanners', dataSearch],
    () => bannerService.fetchBanners(dataSearch),
    { keepPreviousData: true },
  );
  const { results = [], page: currentPage, totalResults, totalPages } = data ?? {};

  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [openUpdatePopup, setOpenUpdatePopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [itemChoice, setItemChoice] = useState<BannerType>();

  return (
    <>
      <div className='flex items-center justify-between gap-3'>
        <div className='flex-1'></div>
        <Button variant='contained' color='success' startIcon={<Add />} onClick={() => setOpenCreatePopup(true)}>
          Thêm banner
        </Button>
        <Button variant='outlined' color='primary' startIcon={<Refresh />} onClick={() => refetch()}>
          Làm mới
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Spinner loading={isFetching}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Banner</TableCell>
                <TableCell>Nội dung</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <div className='flex items-center gap-2'>
                      <CardMedia image={item.image} className='w-28 h-20 border' />
                      {item.name}
                    </div>
                  </TableCell>
                  <TableCell className='max-w-[320px]'>{item.description}</TableCell>
                  <TableCell align='right' className='space-x-2'>
                    <Button
                      variant='outlined'
                      color='secondary'
                      size='small'
                      onClick={() => {
                        setItemChoice(item);
                        setOpenUpdatePopup(true);
                      }}
                    >
                      Cập nhật
                    </Button>
                    <Button
                      variant='outlined'
                      color='error'
                      size='small'
                      onClick={() => {
                        setItemChoice(item);
                        setOpenDeletePopup(true);
                      }}
                    >
                      Xoá
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableRowEmpty visible={!isFetching && results.length === 0} />
            </TableBody>
            <caption>{totalResults ?? 0} Banner</caption>
          </Table>
        </Spinner>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={currentPage ?? 1}
          count={totalPages}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>

      <Dialog open={openCreatePopup}>
        <PopupCreateBanner onClose={() => setOpenCreatePopup(false)} />
      </Dialog>
      <Dialog open={openUpdatePopup}>
        <PopupUpdateBanner item={itemChoice!} onClose={() => setOpenUpdatePopup(false)} />
      </Dialog>
      <Dialog open={openDeletePopup}>
        <PopupDeleteBanner item={itemChoice!} onClose={() => setOpenDeletePopup(false)} />
      </Dialog>
    </>
  );
};

export default BannerList;
