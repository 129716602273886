import { client } from './axios';

const fetchUsers = (params: PaginateParams): Promise<UserPaginateType> => client.get(`/admin/users`, { params });
const createUser = (body: UserCreateType): Promise<UserType> => client.post(`/admin/users`, body);
const updateUser = ({ id, ...body }: UserUpdateType): Promise<UserType> => client.patch(`/admin/users/${id}`, body);

export default {
  fetchUsers,
  createUser,
  updateUser,
};
