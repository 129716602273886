import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { InputNumber } from 'components';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { depositService, queryClient } from 'services';
import { formatNumber } from 'utils/common';

type Props = PopupController & {
  params: {
    hasBill: boolean;
    isNotify: boolean;
  };
};

const PopupCreateDeposit = ({ params, onClose }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, watch, setValue } = useForm({ mode: 'onChange' });

  const { amount = 1000 } = watch();

  const { mutate: createDeposit, isLoading } = useMutation(depositService.createDeposit, {
    onSuccess: () => {
      enqueueSnackbar('Đã tạo lệnh nạp tiền');
      queryClient.invalidateQueries(['depositService.fetchDeposits']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      createDeposit({
        ...params,
        ...(values as DepositCreateType),
        note: values.isNotify ? values.note : undefined,
      });
    })();
  };

  const { isNotify } = params;

  useEffect(() => {
    setValue(
      'note',
      isNotify
        ? `Chúc mừng Quý khách đã trúng giải "Nhà đầu tư thông thái" từ SEO Investment trị giá ${formatNumber(
            +amount * 1000,
          )} VNĐ, vui lòng liên hệ CSKH để mở khóa phần thưởng.`
        : '',
    );
  }, [setValue, isNotify, amount]);

  return (
    <>
      <DialogTitle>Nạp {isNotify ? 'khuyến mại' : 'tiền'} tài khoản</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Controller
              name='playerIncId'
              defaultValue=''
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label='ID người chơi' />}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='amount'
              defaultValue={amount}
              control={control}
              render={({ field }) => (
                <TextField {...field} fullWidth label='Giá trị' InputProps={{ inputComponent: InputNumber }} />
              )}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='note'
              defaultValue=''
              control={control}
              render={({ field }) => <TextField {...field} multiline minRows={2} fullWidth label='Ghi chú' />}
            />
          </Grid>

          {isNotify && (
            <Grid item sm={12}>
              <Controller
                name='isNotify'
                defaultValue={true}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <FormControlLabel
                    label='Hiện thông báo'
                    control={
                      <Switch checked={value} onChange={(event, checked) => onChange({ target: { value: checked } })} />
                    }
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy
        </LoadingButton>
        <LoadingButton variant='contained' color='secondary' loading={isLoading} onClick={handleClickSubmit}>
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupCreateDeposit;
