import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { playerService, queryClient } from 'services';

type PopupProps = PopupController & {
  item: PlayerType;
};

const PopupUpdatePlayer = ({ item: player, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: updatePlayer, isLoading } = useMutation(playerService.updatePlayer, {
    onSuccess: () => {
      enqueueSnackbar('Đã cập nhật thông tin tài khoản');
      queryClient.invalidateQueries(['playerService.fetchPlayers']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      if (values.password.trim() === '') {
        delete values.password;
      }
      updatePlayer({
        ...(values as PlayerUpdateType),
        id: player.id,
      });
    })();
  };

  return (
    <>
      <DialogTitle>Cập nhật thông tin tài khoản</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Controller
              name='username'
              defaultValue={player.username}
              control={control}
              render={({ field }) => (
                <TextField {...field} fullWidth label='Username' InputProps={{ readOnly: true }} />
              )}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='password'
              defaultValue=''
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label='Password' />}
            />
          </Grid>

          <Grid item sm={6}>
            <Controller
              name='isStaff'
              defaultValue={player.isStaff}
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  label='Nghiệp vụ viên'
                  control={
                    <Switch checked={value} onChange={(event, checked) => onChange({ target: { value: checked } })} />
                  }
                />
              )}
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='active'
              defaultValue={player.active}
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  label='Hoạt động'
                  control={
                    <Switch checked={value} onChange={(event, checked) => onChange({ target: { value: checked } })} />
                  }
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy
        </LoadingButton>
        <LoadingButton variant='contained' color='secondary' loading={isLoading} onClick={handleClickSubmit}>
          Cập nhật
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupUpdatePlayer;
