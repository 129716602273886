import { client } from './axios';

const fetchGifts = (params: PaginateParams): Promise<GiftPaginateType> => client.get(`/admin/reward`, { params });
const createGift = (body: GiftCreateType): Promise<GiftType> => client.post(`/admin/reward`, body);
const updateGift = ({ id, ...body }: GiftUpdateType): Promise<GiftType> => client.patch(`/admin/reward/${id}`, body);
const deleteGift = ({ id }: IDType): Promise<GiftType> => client.delete(`/admin/reward/${id}`);

export default {
  fetchGifts,
  createGift,
  updateGift,
  deleteGift,
};
