import { Add, Refresh } from '@mui/icons-material';
import {
  Button,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { userService } from 'services';
import { PopupCreateUser, PopupUpdateUser, USER_TYPES } from './popups';

const UserList = () => {
  const [dataSearch, onSearchChange] = useSearch();

  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [openUpdatePopup, setOpenUpdatePopup] = useState(false);
  const [itemChoice, setItemChoice] = useState<UserType>();

  const { data, isFetching, refetch } = useQuery(
    ['userService.fetchUsers', dataSearch],
    () => userService.fetchUsers(dataSearch),
    { keepPreviousData: true },
  );
  const { results = [], page: currentPage, totalResults, totalPages } = data ?? {};

  return (
    <>
      <div className='flex items-center justify-end gap-3'>
        <Button variant='contained' color='success' startIcon={<Add />} onClick={() => setOpenCreatePopup(true)}>
          Thêm tài khoản
        </Button>
        <Button variant='outlined' color='primary' startIcon={<Refresh />} onClick={() => refetch()}>
          Làm mới
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Spinner loading={isFetching}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Role</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.username}</TableCell>
                  <TableCell>{USER_TYPES.find((type) => type.code === item.role)?.label}</TableCell>
                  <TableCell align='right'>
                    <Button
                      variant='outlined'
                      color='secondary'
                      size='small'
                      onClick={() => {
                        setItemChoice(item);
                        setOpenUpdatePopup(true);
                      }}
                    >
                      Cập nhật
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableRowEmpty visible={!isFetching && results.length === 0} />
            </TableBody>
            <caption>{totalResults ?? 0} Admin</caption>
          </Table>
        </Spinner>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={currentPage ?? 1}
          count={totalPages}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>

      <Dialog open={openCreatePopup}>
        <PopupCreateUser onClose={() => setOpenCreatePopup(false)} />
      </Dialog>
      <Dialog open={openUpdatePopup}>
        <PopupUpdateUser item={itemChoice!} onClose={() => setOpenUpdatePopup(false)} />
      </Dialog>
    </>
  );
};

export default UserList;
