import {
  AccountBalanceWalletOutlined,
  AdminPanelSettingsOutlined,
  ArrowDropDown,
  ArrowLeft,
  BadgeOutlined,
  BeachAccessOutlined,
  Diversity1Outlined,
  HowToVoteOutlined,
  Person3Outlined,
  PrintOutlined,
  PrintTwoTone,
  RocketLaunchOutlined,
  SettingsOutlined,
  TwoWheelerOutlined,
} from '@mui/icons-material';
import { Chip, Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import { styled } from '@mui/styles';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { profileSelector } from 'reducers/profileSlice';
import { privateRoute } from 'routes';

const StyledListItem = styled(ListItemButton)({
  borderRadius: '8px !important',
  padding: '4px 12px !important',
  '&:hover': {
    backgroundColor: 'var(--color-primary-dark) !important',
    color: '#fff',
  },
  '&.Mui-selected': {
    backgroundColor: 'var(--color-primary-main) !important',
    color: '#fff',
  },
  '& .MuiSvgIcon-root': {
    marginRight: 8,
  },
  '& .MuiListItemText-primary': {
    fontWeight: 500,
  },
});

type SubMenuType = {
  path: string;
  name?: string;
  icon?: any;
};

type MenuItemProps = SubMenuType & {
  items?: SubMenuType[];
  visible?: boolean;
};

const MenuItem = ({ path, name, icon, items, visible }: MenuItemProps) => {
  const location = useLocation();
  const [open, setOpen] = useState(location.pathname.startsWith(path));

  if (!visible) return null;
  return (
    <>
      {items ? (
        <StyledListItem onClick={() => setOpen((open) => !open)}>
          {icon}
          <ListItemText>{name}</ListItemText>
          {!open ? <ArrowLeft /> : <ArrowDropDown />}
        </StyledListItem>
      ) : (
        <Link to={path}>
          <StyledListItem selected={location.pathname.startsWith(path)}>
            {icon}
            <ListItemText>{name}</ListItemText>
          </StyledListItem>
        </Link>
      )}

      {items && (
        <Collapse in={open}>
          <List className='ml-3 py-0 List-chips'>
            {items?.map((sub, index) => (
              <MenuItem key={index} {...sub} visible />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

const Menu = () => {
  const { isAdmin, isSale, isAccount } = useSelector(profileSelector);

  return (
    <List className='flex flex-col gap-1'>
      <MenuItem {...privateRoute.home} icon={<SettingsOutlined />} visible={isSale} />
      <MenuItem {...privateRoute.users} icon={<AdminPanelSettingsOutlined />} visible={isAdmin} />
      <MenuItem {...privateRoute.players} icon={<Person3Outlined />} visible={isSale || isAccount} />
      <MenuItem {...privateRoute.deposits} icon={<AccountBalanceWalletOutlined />} visible={isSale || isAccount} />
      <MenuItem {...privateRoute.withdraws} icon={<PrintOutlined />} visible={isSale || isAccount} />
      <MenuItem {...privateRoute.bets} icon={<HowToVoteOutlined />} visible={isSale} />
      <MenuItem
        {...privateRoute.sessions}
        icon={<RocketLaunchOutlined />}
        items={[
          { path: privateRoute.sessionView.url('1'), icon: <Chip label='LEVEL 1' /> },
          { path: privateRoute.sessionView.url('3'), icon: <Chip label='LEVEL 3' /> },
          { path: privateRoute.sessionView.url('5'), icon: <Chip label='LEVEL 5' /> },
          { path: privateRoute.sessionView.url('10'), icon: <Chip label='LEVEL 10' /> },
        ]}
        visible={isSale}
      />
      <MenuItem {...privateRoute.withdrawStats} icon={<PrintTwoTone />} visible={isAdmin} />

      <MenuItem {...privateRoute.partner} icon={<BadgeOutlined />} visible={false} />
      <MenuItem {...privateRoute.gifts} icon={<BeachAccessOutlined />} visible={isSale} />
      <MenuItem {...privateRoute.rewards} icon={<TwoWheelerOutlined />} visible={isSale} />
      <MenuItem {...privateRoute.tops} icon={<Diversity1Outlined />} visible={isSale} />
    </List>
  );
};

export default Menu;
