import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { rewardService, queryClient } from 'services';

type Props = PopupController & {
  item: RewardType;
};

const PopupApproveReward = ({ item, onClose }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: exchangeReward, isLoading } = useMutation(rewardService.exchangeReward, {
    onSuccess: () => {
      enqueueSnackbar('Đã quy đổi thành điểm');
      queryClient.invalidateQueries(['rewardService.fetchRewards']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      exchangeReward({
        id: item.id,
      });
    })();
  };

  return (
    <>
      <DialogTitle>Quy đổi phần thưởng thành điểm</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Controller
              name='note'
              defaultValue=''
              control={control}
              render={({ field }) => (
                <TextField {...field} multiline minRows={2} fullWidth label='Ghi chú' placeholder='OK' />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy
        </LoadingButton>
        <LoadingButton variant='contained' color='success' loading={isLoading} onClick={handleClickSubmit}>
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupApproveReward;
