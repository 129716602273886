import { client } from './axios';

const fetchTops = (params: PaginateParams): Promise<TopPaginateType> => client.get(`/admin/top`, { params });
const createTop = (body: TopCreateType): Promise<TopType> => client.post(`/admin/top`, body);
const updateTop = ({ id, ...body }: TopUpdateType): Promise<TopType> => client.patch(`/admin/top/${id}`, body);
const deleteTop = ({ id }: IDType): Promise<TopType> => client.delete(`/admin/top/${id}`);

export default {
  fetchTops,
  createTop,
  updateTop,
  deleteTop,
};
