import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { depositService, queryClient } from 'services';

type PopupProps = PopupController & {
  item: DepositType;
};

const PopupRevokeDeposit = ({ item, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: revokeDeposit, isLoading } = useMutation(depositService.revokeDeposit, {
    onSuccess: () => {
      enqueueSnackbar('Đã thu hồi lệnh nạp tiền');
      queryClient.invalidateQueries(['depositService.fetchDeposits']);
      onClose();
    },
  });

  const handleClickConfirm = () => {
    revokeDeposit({ id: item.id });
  };

  return (
    <>
      <DialogTitle>Thu hồi lệnh</DialogTitle>

      <DialogContent>
        <div>Bạn có chắc muốn thu hồi lệnh nạp tiền?</div>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy
        </LoadingButton>
        <LoadingButton variant='contained' color='error' loading={isLoading} onClick={handleClickConfirm}>
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupRevokeDeposit;
