import { client } from './axios';

const fetchPartners = (params: PaginateParams): Promise<PartnerPaginateType> =>
  client.get(`/admin/partner`, { params });
const createPartner = (body: PartnerCreateType): Promise<PartnerType> => client.post(`/admin/partner`, body);
const updatePartner = ({ id, ...body }: PartnerUpdateType): Promise<PartnerType> =>
  client.patch(`/admin/partner/${id}`, body);
const deletePartner = ({ id }: IDType): Promise<PartnerType> => client.delete(`/admin/partner/${id}`);

export default {
  fetchPartners,
  createPartner,
  updatePartner,
  deletePartner,
};
