import { Refresh } from '@mui/icons-material';
import {
  Button,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { withdrawService } from 'services';
import { formatNumber, formatDate } from 'utils/common';

const WithdrawStats = () => {
  const [dataSearch, onSearchChange] = useSearch();

  const { data, isFetching, refetch } = useQuery(
    ['withdrawService.fetchStats', dataSearch],
    () => withdrawService.fetchStats(dataSearch),
    { keepPreviousData: true },
  );
  const { results = [], page: currentPage, totalResults, totalPages } = data ?? {};

  return (
    <>
      <div className='flex items-center justify-between'>
        <div></div>
        <Button variant='outlined' color='primary' startIcon={<Refresh />} onClick={() => refetch()}>
          Làm mới
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Spinner loading={isFetching}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID người chơi</TableCell>
                <TableCell align='right'>Giá trị</TableCell>
                <TableCell>Ngân hàng</TableCell>
                <TableCell>Số tài khoản</TableCell>
                <TableCell>Chủ tài khoản</TableCell>
                <TableCell>Thời gian</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((item) => (
                <TableRow key={item._id}>
                  <TableCell>{item.playerIncId}</TableCell>
                  <TableCell align='right'>{formatNumber(item.amount)}</TableCell>
                  <TableCell>{item.bankName}</TableCell>
                  <TableCell>{item.bankAccountNumber}</TableCell>
                  <TableCell>{item.bankAccountHolder}</TableCell>
                  <TableCell>{formatDate(item.createdAt)}</TableCell>
                </TableRow>
              ))}
              <TableRowEmpty visible={!isFetching && results.length === 0} />
            </TableBody>
            <caption>{totalResults ?? 0} Lệnh</caption>
          </Table>
        </Spinner>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={currentPage ?? 1}
          count={totalPages}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>
    </>
  );
};

export default WithdrawStats;
