import { Refresh } from '@mui/icons-material';
import {
  Button,
  Chip,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CommonSearch, Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { withdrawService } from 'services';
import { formatNumber, formatDate } from 'utils/common';
import { PopupApproveWithdraw, PopupRejectWithdraw, PopupUpdateWithdraw } from './popups';

const WithdrawList = () => {
  const { isAccount } = useSelector(profileSelector);

  const [dataSearch, onSearchChange] = useSearch();

  const { data, isFetching, refetch } = useQuery(
    ['withdrawService.fetchWithdraws', dataSearch],
    () => withdrawService.fetchWithdraws(dataSearch),
    { keepPreviousData: true },
  );
  const { results = [], page: currentPage, totalResults, totalPages } = data ?? {};

  const [openApprovePopup, setOpenApprovePopup] = useState(false);
  const [openRejectPopup, setOpenRejectPopup] = useState(false);
  const [openUpdatePopup, setOpenUpdatePopup] = useState(false);
  const [itemChoice, setItemChoice] = useState<WithdrawType>();

  return (
    <>
      <div className='flex items-center justify-between'>
        <CommonSearch
          items={[
            { label: 'ID người chơi', key: 'playerIncId' },
            { label: 'Số tài khoản', key: 'bankAccountNumber' },
          ]}
          onChange={onSearchChange}
        />
        <Button variant='outlined' color='primary' startIcon={<Refresh />} onClick={() => refetch()}>
          Làm mới
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Spinner loading={isFetching}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID người chơi</TableCell>
                <TableCell align='right'>Giá trị</TableCell>
                <TableCell>Trạng thái</TableCell>
                <TableCell>Ngân hàng</TableCell>
                <TableCell>Số tài khoản</TableCell>
                <TableCell>Chủ tài khoản</TableCell>
                <TableCell>Thời gian</TableCell>
                <TableCell>Ghi chú</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.player.incId}</TableCell>
                  <TableCell align='right'>{formatNumber(item.amount)}</TableCell>
                  <TableCell>
                    {item.status === 'PENDING' && <Chip label='Chờ xử lý' color='secondary' />}
                    {item.status === 'APPROVED' && <Chip label='Đã duyệt' color='success' />}
                    {item.status === 'REJECTED' && <Chip label='Đã hủy' color='error' />}
                  </TableCell>
                  <TableCell>{item.bankName}</TableCell>
                  <TableCell>{item.bankAccountNumber}</TableCell>
                  <TableCell>{item.bankAccountHolder}</TableCell>
                  <TableCell>{formatDate(item.createdAt)}</TableCell>
                  <TableCell>{item.note}</TableCell>
                  <TableCell align='right' className='whitespace-nowrap space-x-2'>
                    {isAccount && (
                      <Button
                        variant='outlined'
                        color='success'
                        size='small'
                        disabled={item.status !== 'PENDING'}
                        onClick={() => {
                          setItemChoice(item);
                          setOpenApprovePopup(true);
                        }}
                      >
                        Duyệt
                      </Button>
                    )}
                    {isAccount && (
                      <Button
                        variant='outlined'
                        color='error'
                        size='small'
                        disabled={item.status !== 'PENDING'}
                        onClick={() => {
                          setItemChoice(item);
                          setOpenRejectPopup(true);
                        }}
                      >
                        Hủy
                      </Button>
                    )}
                    <Button
                      variant='outlined'
                      color='secondary'
                      size='small'
                      onClick={() => {
                        setItemChoice(item);
                        setOpenUpdatePopup(true);
                      }}
                    >
                      Cập nhật
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableRowEmpty visible={!isFetching && results.length === 0} />
            </TableBody>
            <caption>{totalResults ?? 0} Lệnh</caption>
          </Table>
        </Spinner>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={currentPage ?? 1}
          count={totalPages}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>

      <Dialog open={openApprovePopup}>
        <PopupApproveWithdraw item={itemChoice!} onClose={() => setOpenApprovePopup(false)} />
      </Dialog>
      <Dialog open={openRejectPopup}>
        <PopupRejectWithdraw item={itemChoice!} onClose={() => setOpenRejectPopup(false)} />
      </Dialog>
      <Dialog open={openUpdatePopup}>
        <PopupUpdateWithdraw item={itemChoice!} onClose={() => setOpenUpdatePopup(false)} />
      </Dialog>
    </>
  );
};

export default WithdrawList;
