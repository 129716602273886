import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { withdrawService, queryClient } from 'services';

type Props = PopupController & {
  item: WithdrawType;
};

const PopupUpdateWithdraw = ({ item, onClose }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: updateWithdraw, isLoading } = useMutation(withdrawService.updateWithdraw, {
    onSuccess: () => {
      enqueueSnackbar('Đã cập nhật nội dung');
      queryClient.invalidateQueries(['withdrawService.fetchWithdraws']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      updateWithdraw({
        ...(values as WithdrawUpdateType),
        id: item.id,
      });
    })();
  };

  return (
    <>
      <DialogTitle>Cập nhật nội dung</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Controller
              name='bankName'
              defaultValue={item.bankName}
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label='Ngân hàng' />}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='bankAccountNumber'
              defaultValue={item.bankAccountNumber}
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label='Số tài khoản' />}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='bankAccountHolder'
              defaultValue={item.bankAccountHolder}
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label='Chủ tài khoản' />}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='note'
              defaultValue={item.note}
              control={control}
              render={({ field }) => <TextField {...field} multiline minRows={2} fullWidth label='Ghi chú' />}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy
        </LoadingButton>
        <LoadingButton variant='contained' color='secondary' loading={isLoading} onClick={handleClickSubmit}>
          Cập nhật
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupUpdateWithdraw;
