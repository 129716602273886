import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { InputNumber } from 'components';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { playerService, queryClient } from 'services';
import { formatNumber } from 'utils/common';

type PopupProps = PopupController & {
  item: PlayerType;
};

const PopupBalancePlayer = ({ item: player, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: takeBackMoney, isLoading } = useMutation(playerService.takeBackMoney, {
    onSuccess: () => {
      enqueueSnackbar('Đã thu hồi số dư tài khoản');
      queryClient.invalidateQueries(['playerService.fetchPlayers']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      takeBackMoney({
        ...(values as PlayerBalanceType),
        id: player.id,
      });
    })();
  };

  return (
    <>
      <DialogTitle>Thu hồi số dư tài khoản</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item sm={12} className='space-y-3'>
            <div>Tổng nạp: {formatNumber(player.totalDeposit)}</div>
            <div>Số dư hiện tại: {formatNumber(player.balance)}</div>
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='amount'
              defaultValue=''
              control={control}
              render={({ field }) => (
                <TextField {...field} fullWidth label='Giá trị' InputProps={{ inputComponent: InputNumber }} />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy
        </LoadingButton>
        <LoadingButton variant='contained' color='error' loading={isLoading} onClick={handleClickSubmit}>
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupBalancePlayer;
