import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { queryClient, userService } from 'services';
import { USER_TYPES } from '.';

type PopupProps = PopupController & {
  item: UserType;
};

const PopupUpdateUser = ({ item: user, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: updateUser, isLoading } = useMutation(userService.updateUser, {
    onSuccess: () => {
      enqueueSnackbar('Đã cập nhật thông tin tài khoản');
      queryClient.invalidateQueries(['userService.fetchUsers']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      if (values.password.trim() === '') {
        delete values.password;
      }
      updateUser({
        ...(values as UserUpdateType),
        id: user.id!,
      });
    })();
  };

  return (
    <>
      <DialogTitle>Cập nhật thông tin tài khoản</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Controller
              name='username'
              defaultValue={user.username}
              control={control}
              render={({ field }) => (
                <TextField {...field} fullWidth label='Username' InputProps={{ readOnly: true }} />
              )}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='password'
              defaultValue=''
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label='Password' />}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='role'
              defaultValue={user.role}
              control={control}
              render={({ field }) => (
                <TextField {...field} fullWidth select label='Vai trò'>
                  {USER_TYPES.map((item, index) => (
                    <MenuItem key={index} value={item.code}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy
        </LoadingButton>
        <LoadingButton variant='contained' color='secondary' loading={isLoading} onClick={handleClickSubmit}>
          Cập nhật
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupUpdateUser;
