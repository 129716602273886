import { client } from './axios';

const fetchDeposits = (params: PaginateParams): Promise<DepositPaginateType> =>
  client.get(`/admin/deposits`, { params });
const createDeposit = (body: DepositCreateType): Promise<DepositType> => client.post(`/admin/deposits`, body);
const updateDeposit = ({ id, ...body }: DepositUpdateType): Promise<DepositType> =>
  client.patch(`/admin/deposits/${id}/note`, body);
const revokeDeposit = ({ id }: IDType): Promise<DepositType> => client.post(`/admin/deposits/${id}/revoke`);

export default {
  fetchDeposits,
  createDeposit,
  updateDeposit,
  revokeDeposit,
};
