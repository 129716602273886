import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { depositService, queryClient } from 'services';

type Props = PopupController & {
  item: DepositType;
};

const PopupUpdateDeposit = ({ item, onClose }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: updateDeposit, isLoading } = useMutation(depositService.updateDeposit, {
    onSuccess: () => {
      enqueueSnackbar('Đã cập nhật ghi chú');
      queryClient.invalidateQueries(['depositService.fetchDeposits']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      updateDeposit({
        ...(values as DepositUpdateType),
        id: item.id,
      });
    })();
  };

  return (
    <>
      <DialogTitle>Sửa ghi chú</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Controller
              name='note'
              defaultValue={item.note}
              control={control}
              render={({ field }) => <TextField {...field} multiline minRows={2} fullWidth label='Ghi chú' />}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy
        </LoadingButton>
        <LoadingButton variant='contained' color='secondary' loading={isLoading} onClick={handleClickSubmit}>
          Cập nhật
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupUpdateDeposit;
