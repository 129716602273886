import { client } from './axios';

const fetchRewards = (params: PaginateParams): Promise<RewardPaginateType> => client.get(`/admin/gift`, { params });
const exchangeReward = ({ id }: IDType): Promise<RewardType> => client.post(`/admin/gift/${id}/exchange`);
const deleteReward = ({ id }: IDType): Promise<GiftType> => client.delete(`/admin/gift/${id}`);

export default {
  fetchRewards,
  exchangeReward,
  deleteReward,
};
