import { AddAPhoto } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { CardMedia, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { fileService, bannerService, queryClient } from 'services';

const PopupCreateBanner = ({ onClose }: PopupController) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, setValue } = useForm({ mode: 'onChange' });

  const { mutate: createBanner, isLoading } = useMutation(bannerService.createBanner, {
    onSuccess: () => {
      enqueueSnackbar('Đã thêm mới banner');
      queryClient.invalidateQueries(['bannerService.fetchBanners']);
      onClose();
    },
  });

  const handleChangeFile = (event: any) => {
    const [file] = event.target.files;
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    fileService.uploadFile(formData).then(({ fileUrl }) => {
      setValue('image', fileUrl, { shouldValidate: true });
    });
  };

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      createBanner({
        ...(values as BannerCreateType),
        index: 1,
      });
    })();
  };

  return (
    <>
      <DialogTitle>Thêm banner</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Controller
              name='image'
              defaultValue=''
              control={control}
              rules={{ required: true }}
              render={({ field: { value: image }, fieldState: { error } }) => (
                <>
                  <input hidden type='file' id='image' accept='image/*' onChange={handleChangeFile} />
                  <label
                    htmlFor='image'
                    className='flex rounded border-2 border-dashed cursor-pointer hover:border-neutral-400'
                    style={error ? { borderColor: 'orangered' } : {}}
                  >
                    <CardMedia
                      image={image}
                      className='flex justify-center items-center bg-contain bg-neutral-50 h-52 w-full'
                    >
                      {!image && <AddAPhoto className='w-16 h-16' sx={{ color: 'silver' }} />}
                    </CardMedia>
                  </label>
                </>
              )}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='name'
              defaultValue=''
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label='Tên' />}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='description'
              defaultValue=''
              control={control}
              render={({ field }) => <TextField {...field} fullWidth multiline minRows={2} label='Nội dung' />}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy
        </LoadingButton>
        <LoadingButton variant='contained' color='success' loading={isLoading} onClick={handleClickSubmit}>
          Thêm banner
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupCreateBanner;
