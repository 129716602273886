import { Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Container, Grid, TextField } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { InputNumber } from 'components';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { homepageService, queryClient } from 'services';
import { BannerList } from 'views/Banner';

const Home = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { isAdmin, isSale } = useSelector(profileSelector);
  const { control, handleSubmit, setValue } = useForm({ mode: 'onChange' });

  const { data } = useQuery(['homepageService.getHomepage'], () => homepageService.getHomepage(), {
    onSuccess: (data) => {
      Object.entries(data).forEach(([key, value]) => {
        setValue(key, value);
      });
    },
  });

  const { mutate: updateHomepage, isLoading } = useMutation(homepageService.updateHomepage, {
    onSuccess: () => {
      enqueueSnackbar('Đã cập nhật trang chủ');
      queryClient.invalidateQueries(['homepageService.getHomepage']);
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      updateHomepage({
        ...(values as HomepageUpdateType),
        id: data?.id!,
      });
    })();
  };

  return (
    <div className='flex items-start gap-10'>
      <Container maxWidth='lg' className='flex-1 ml-0 px-0'>
        <BannerList />
      </Container>
      <div hidden={!isAdmin && !isSale} className='min-w-[420px] max-w-[600px]'>
        <div className='flex items-center justify-between gap-3 mb-6'>
          <div className='flex-1'></div>
          <LoadingButton
            variant='contained'
            color='secondary'
            startIcon={<Save />}
            loading={isLoading}
            onClick={handleClickSubmit}
          >
            Lưu
          </LoadingButton>
        </div>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Controller
              name='notification'
              defaultValue=''
              control={control}
              render={({ field }) => <TextField {...field} multiline minRows={2} fullWidth label='Chạy thông báo' />}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='totalUser'
              defaultValue={data?.totalUser ?? ''}
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label='Số lượng người chơi' />}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='numberOfSupplier'
              defaultValue=''
              control={control}
              render={({ field }) => (
                <TextField {...field} fullWidth label='Số lượng đối tác' InputProps={{ inputComponent: InputNumber }} />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='numberOfMember'
              defaultValue=''
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label='Thành viên' />}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='numberOfCollaborators'
              defaultValue=''
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label='Cộng tác viên' />}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='potentialCustomers'
              defaultValue=''
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label='Khách hàng tiềm năng' />}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Home;
