import { client } from './axios';

const fetchPlayers = (params: PaginateParams): Promise<PlayerPaginateType> => client.get(`/admin/players`, { params });
const createPlayer = (body: PlayerCreateType): Promise<PlayerType> => client.post(`/admin/players`, body);
const updatePlayer = ({ id, ...body }: PlayerUpdateType): Promise<PlayerType> =>
  client.patch(`/admin/players/${id}`, body);
const takeBackMoney = ({ id, ...body }: PlayerBalanceType): Promise<PlayerType> =>
  client.post(`/admin/players/${id}/take-back-money`, body);
const resetBox = ({ id }: IDType): Promise<PlayerType> => client.post(`/admin/players/${id}/reset-box`);

export default {
  fetchPlayers,
  createPlayer,
  updatePlayer,
  takeBackMoney,
  resetBox,
};
