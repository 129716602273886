import { BetList } from 'views/Bet';
import { DepositList } from 'views/Deposit';
import { Home } from 'views/Home';
import { PlayerList } from 'views/Player';
import { GiftList } from 'views/Gift';
import { SessionList } from 'views/Session';
import { UserList } from 'views/User';
import { WithdrawList, WithdrawStats } from 'views/Withdraw';
import { RewardList } from 'views/Reward';
import { PartnerList } from 'views/Partner';
import { TopList } from 'views/Top';

const privateRoute = {
  home: {
    path: '/home',
    name: 'Cài đặt Trang chủ',
    component: Home,
  },
  users: {
    path: '/users',
    name: 'Quản lý Admin',
    component: UserList,
  },
  players: {
    path: '/players',
    name: 'Quản lý Người chơi',
    component: PlayerList,
  },
  deposits: {
    path: '/deposits',
    name: 'Quản lý Nạp tiền',
    component: DepositList,
  },
  withdraws: {
    path: '/withdraws',
    name: 'Quản lý Rút tiền',
    component: WithdrawList,
  },
  withdrawStats: {
    path: '/withdraw-stats',
    name: 'Thống kê Lệnh rút',
    component: WithdrawStats,
  },
  bets: {
    path: '/bets',
    name: 'Quản lý Đặt cược',
    component: BetList,
  },
  sessions: {
    path: '/sessions',
    name: 'Kết quả xổ số',
    component: SessionList,
  },
  sessionView: {
    path: '/sessions/:zone/results',
    url: (zone: string) => `/sessions/${zone}/results`,
    name: 'Kết quả xổ số',
    component: SessionList,
  },

  partner: {
    path: '/partners',
    name: 'Quản lý Đối tác',
    component: PartnerList,
  },
  gifts: {
    path: '/gifts',
    name: 'Quản lý Quà tặng',
    component: GiftList,
  },
  rewards: {
    path: '/rewards',
    name: 'Quản lý Nhận thưởng',
    component: RewardList,
  },
  tops: {
    path: '/tops',
    name: 'Bảng xếp hạng',
    component: TopList,
  },
};

export default privateRoute;
