import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { playerService, queryClient } from 'services';

const PopupCreatePlayer = ({ onClose }: PopupController) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: createPlayer, isLoading } = useMutation(playerService.createPlayer, {
    onSuccess: () => {
      enqueueSnackbar('Đã tạo tài khoản');
      queryClient.invalidateQueries(['playerService.fetchPlayers']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      createPlayer({
        ...(values as PlayerCreateType),
      });
    })();
  };

  return (
    <>
      <DialogTitle>Tạo tài khoản</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Controller
              name='username'
              defaultValue=''
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label='Username' />}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name='password'
              defaultValue=''
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label='Password' />}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy
        </LoadingButton>
        <LoadingButton variant='contained' color='success' loading={isLoading} onClick={handleClickSubmit}>
          Tạo tài khoản
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupCreatePlayer;
