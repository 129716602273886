import { client } from './axios';

const fetchSessions = (params: PaginateParams): Promise<SessionPaginateType> =>
  client.get(`/admin/sessions`, { params });
const updateSession = ({ id, ...body }: SessionUpdateType): Promise<SessionType> =>
  client.patch(`/admin/sessions/${id}`, body);

export default {
  fetchSessions,
  updateSession,
};
