import { Add, Refresh } from '@mui/icons-material';
import {
  Button,
  Chip,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { CommonSearch, Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { playerService, queryClient } from 'services';
import { formatNumber } from 'utils/common';
import { PopupBalancePlayer, PopupCreatePlayer, PopupUpdatePlayer, PopupViewPlayer } from './popups';

const PlayerList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { isSale, isAccount } = useSelector(profileSelector);

  const [dataSearch, onSearchChange] = useSearch();

  const { data, isFetching, refetch } = useQuery(
    ['playerService.fetchPlayers', dataSearch],
    () => playerService.fetchPlayers(dataSearch),
    { keepPreviousData: true },
  );
  const { results = [], page: currentPage, totalResults, totalPages } = data ?? {};

  const { mutate: resetBox } = useMutation(playerService.resetBox, {
    onSuccess: () => {
      enqueueSnackbar('Đã reset số box mở');
      queryClient.invalidateQueries(['playerService.fetchPlayers']);
    },
  });

  const [openViewPopup, setOpenViewPopup] = useState(false);
  const [openCreatePopup, setOpenCreatePopup] = useState(false);
  const [openUpdatePopup, setOpenUpdatePopup] = useState(false);
  const [openBalancePopup, setOpenBalancePopup] = useState(false);
  const [itemChoice, setItemChoice] = useState<PlayerType>();

  return (
    <>
      <div className='flex items-center justify-between gap-3'>
        <CommonSearch items={[{ label: 'ID', key: 'incId' }]} onChange={onSearchChange} />
        {isSale && (
          <Button variant='contained' color='success' startIcon={<Add />} onClick={() => setOpenCreatePopup(true)}>
            Thêm tài khoản
          </Button>
        )}
        <Button variant='outlined' color='primary' startIcon={<Refresh />} onClick={() => refetch()}>
          Làm mới
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Spinner loading={isFetching}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Trạng thái</TableCell>
                <TableCell>Online</TableCell>
                <TableCell>Nghiệp vụ</TableCell>
                <TableCell align='right'>Số dư</TableCell>
                <TableCell align='right'>Chủ tài khoản</TableCell>
                <TableCell>Số box mở</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.incId}</TableCell>
                  <TableCell>{item.username}</TableCell>
                  <TableCell>
                    {item.active ? (
                      <Chip label='Hoạt động' color='secondary' />
                    ) : (
                      <Chip label='Đã vô hiệu' color='error' />
                    )}
                  </TableCell>
                  <TableCell>
                    {item.online ? (
                      <Chip label='Trực tuyến' color='success' />
                    ) : (
                      <Chip label='Ngoại tuyến' color='default' />
                    )}
                  </TableCell>
                  <TableCell>
                    {item.isStaff ? <Chip label='Nghiệp vụ viên' color='info' className='max-w-[140px]' /> : ''}
                  </TableCell>
                  <TableCell align='right'>{formatNumber(item.balance)}</TableCell>
                  <TableCell align='right'>{item.bankAccountHolder}</TableCell>
                  <TableCell className='whitespace-nowrap'>
                    <span className='inline-block w-[20px] mr-3'>{item.openBoxed}</span>
                    <Button
                      variant='outlined'
                      size='small'
                      startIcon={<Refresh />}
                      onClick={() => resetBox({ id: item.id })}
                    >
                      Reset
                    </Button>
                  </TableCell>
                  <TableCell align='right' className='whitespace-nowrap space-x-2'>
                    <Button
                      variant='outlined'
                      color='info'
                      size='small'
                      onClick={() => {
                        setItemChoice(item);
                        setOpenViewPopup(true);
                      }}
                    >
                      Xem
                    </Button>
                    <Button
                      variant='outlined'
                      color='secondary'
                      size='small'
                      onClick={() => {
                        setItemChoice(item);
                        setOpenUpdatePopup(true);
                      }}
                    >
                      Cập nhật
                    </Button>
                    {isAccount && (
                      <Button
                        variant='outlined'
                        color='error'
                        size='small'
                        onClick={() => {
                          setItemChoice(item);
                          setOpenBalancePopup(true);
                        }}
                      >
                        Thu hồi
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              <TableRowEmpty visible={!isFetching && results.length === 0} />
            </TableBody>
            <caption>{totalResults ?? 0} Người chơi</caption>
          </Table>
        </Spinner>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={currentPage ?? 1}
          count={totalPages}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>

      <Dialog open={openViewPopup}>
        <PopupViewPlayer item={itemChoice!} onClose={() => setOpenViewPopup(false)} />
      </Dialog>
      <Dialog open={openCreatePopup}>
        <PopupCreatePlayer onClose={() => setOpenCreatePopup(false)} />
      </Dialog>
      <Dialog open={openUpdatePopup}>
        <PopupUpdatePlayer item={itemChoice!} onClose={() => setOpenUpdatePopup(false)} />
      </Dialog>
      <Dialog open={openBalancePopup}>
        <PopupBalancePlayer item={itemChoice!} onClose={() => setOpenBalancePopup(false)} />
      </Dialog>
    </>
  );
};

export default PlayerList;
